<template>
  <div class="content">
        <div class="content-top">
            <!-- 面包屑 -->
            <div class="breadcrumbs">
                <i></i>
                <a href="/">首页</a><span>></span>
                <a>新闻资讯</a><span>></span>
                <a href="#" class="active">公示公告</a>
            </div>
            <!-- 侧边栏 + 头部内容 -->
            <div class="top-sidebar">
                <!-- 侧边栏 -->
                
                <div class=" fl fw">
                  <div class="one">
                    <img class="one_img" src="/static/img/icon_title.png" alt="" />
                    <span class="one_span">公示公告</span>
                  </div>
                  <div class="h_div"></div>
                  <div class="two tcontent">
                    <h2 style="text-align: center;">{{ record.title }}</h2>
                    <p class="meta">{{ record.create_time }}</p>
                    <p v-html="htmlContent"></p>
                  </div>

                </div>
                <div class="right sidebar">
                    <ul>
                        <li>
                            <div class="sidebar-title active">
                                <span>公司资讯</span>
                            </div>
                            <!-- 子菜单 -->
                            <ul class="sidebar-health">   
                              <li  @click="gotoAbout('/news/1')">颐悦要闻</li>
                              <li  @click="gotoAbout('/news/5')">公司动态</li>
                              <li  @click="gotoAbout('/news/6')">行业资讯</li>
                              <li id="gsgg" class="health-active">公示公告</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div style="clear:both;"></div>
            </div>
            
    </div>
    </div>
<div style="clear:both;"></div>

</template>

<style src="../../../public/static/css/claim-download.css" />
<style src="../../../public/static/css/news.css" />
<style>
  body{background: #f6f5f4 !important;}
  .fw{width:calc(100% - 207px);}
  .tcontent{padding-top: 10px; padding-bottom: 10px;font: 18px/34px Arial,Hiragino Sans GB,STHeiti,Helvetica Neue,Helvetica,Microsoft Yahei,WenQuanYi Micro Hei,sans-serif; color: #404040;}
  .tcontent p{margin: 34px 0;
    text-align: justify;
    text-indent: 0;color: #404040;}
  .meta{color: #999 !important;font-size: 15px; margin-top: 5px !important;border-bottom: 1px solid #ccc; margin-bottom: 10px;text-align: center !important;}
</style>


<script>

import * as Api from '@/api'
export default {
  data() {
    return {
      recordId: null,
      htmlContent: '',
      record: {}
    }
  },
  created() {
    this.recordId = this.$route.params.id
    //console.log(this.$route.params)
    this.loadData()
  },
  methods: {
    loadData() {
      Api.noticeinfo({id: this.recordId}).then(response=>{
        console.log(response)
        this.record = response.data.data.detail
        this.htmlContent = this.record.content
      })
    }
  }
}

</script>